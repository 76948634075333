import React from "react";
import {FormattedMessage, useIntl} from "react-intl";
import styled from "styled-components";
// @ts-ignore
import facebook from "./facebook.svg";
// @ts-ignore
import instagram from "./instagram.svg";
// @ts-ignore
import linkedin from "./linkedin.svg";

const Logo = styled.img`
  height: 24px;
  width: 24px;
  object-fit: contain;
`;

const SocialMediaUl = styled.ul`
  flex-direction: row;
  @media (max-width: ${(props) => props.theme.breakpointsNo.md - 1 + "px"}) {
    margin-top: 8px;
  }
`;

export default () => {
  const intl = useIntl();
  const navItems = [
    {
      title: intl.formatMessage({id: "nav.contact"}),
      href: intl.formatMessage({id: "link.philatelie.contact"})
    },
    {
      title: intl.formatMessage({id: "nav.philatelie"}),
      href: intl.formatMessage({id: "link.philatelie"})
    },
    {
      title: intl.formatMessage({id: "nav.privacy"}),
      href: "https://www.philatelie.li/bc/datenschutzerklaerung.html"
    },
    {
      title: intl.formatMessage({id: "nav.impressum"}),
      href: "https://www.philatelie.li/shop/USER_ADMIN_HILFE.php?Hilfe_ID=impressum"
    },
    {
      title: intl.formatMessage({id: "nav.tos"}),
      href: "https://www.philatelie.li/bc/agb.html"
    }
  ];

  const socialMedia = [
    {
      src: facebook,
      href: "https://www.facebook.com/philatelie.liechtenstein"
    },
    {
      src: instagram,
      href: "https://www.instagram.com/kryptobriefmarke.li/"
    },
    {
      src: linkedin,
      href: "https://www.linkedin.com/company/philatelie-liechtenstein/"
    }
  ];

  return (
    <footer className="text-center">
      <span className="d-md-block d-none copyright">
        &#169; <FormattedMessage id="company.philatelie" /> 2024
      </span>
      <span className="d-md-none d-block">
        &#169; <FormattedMessage id="company.philatelie" /> 2024
      </span>
      <div className="row m-0">
        <div className="col-2"></div>
        <div className="col-8">
          <nav>
            <ul className="mt-1">
              {navItems.map((item, idx) => (
                <li key={idx}>
                  <a
                    href={item.href}
                    className="text-decoration-none"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.title}
                  </a>
                </li>
              ))}
            </ul>
          </nav>
        </div>
        <div className="col-12 col-md-2">
          <nav>
            <SocialMediaUl>
              {socialMedia.map((item, idx) => (
                <li key={idx}>
                  <a
                    href={item.href}
                    className="text-decoration-none"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Logo src={item.src} />
                  </a>
                </li>
              ))}
            </SocialMediaUl>
          </nav>
        </div>
      </div>
    </footer>
  );
};
